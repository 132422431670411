import React from "react"
import Crate from "./crate"
import icon_a from "../assets/header-icon-a.png"
import icon_b from "../assets/header-icon-b.png"
import styled from "@emotion/styled"
import { FormattedMessage } from 'react-intl'

class ComponentHeader extends React.Component {
  
  render() {
    
    const title = this.props.title
    const icon = (this.props.icon === "icon-a") ? icon_a : icon_b;

    const SubHeader = styled.div`
      :after{
        background-image: url('${icon}');
      }
    `
    return (
      <Crate className="bg-blue-100">
        <div className="container px-5 md:px-0 mx-auto">
          <SubHeader className="flex items-center sub-header">
            <h1 className="tk-museo text-mlg text-purple-900">
              <FormattedMessage id={title} />
            </h1>
          </SubHeader>
        </div>
      </Crate>
    )
  }
}

export default ComponentHeader