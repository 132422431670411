import React from "react"
import TranslateLink from './translateLink'
import { FormattedMessage } from 'react-intl'
import Crate from '../components/crate';
import styled from "@emotion/styled"

import { Location } from "@reach/router";
import { FiExternalLink, FiX, FiChevronDown } from "react-icons/fi";

const Center = styled.div`
  > div > span {
    border-bottom: 5px solid transparent;
  }
  > div > span.nav-active{
    border-bottom: 5px solid #FBB040;
  }
  > div > span > div{
    display:none;
  }
  > div > span:hover > div{
    display: block;
  }
`

const SubMenu = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  min-width: 200px;
  z-index: 999;
  > a {
    background-color: #FBB040;
  }
`

const itemClass = "px-4 py-1 block";

class ComponentNavTop extends React.Component {

  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      messageState: 'hide',
    };
  }

  navClass(currentPath, cls, paths) {
    return Array.isArray(paths) && paths.includes(currentPath)
      ? `${cls} nav-active`
      : cls;
  }

  toggleMessage = (event) => {
    this.setState({
      messageState: this.state.messageState === 'show' ? 'hide' : 'show'
    });
  }

  render() {

    const props = this.props;
    const locale = (props.locale === undefined) ? "en" : props.locale;
    const messageState = this.state.messageState

    const vc2024Link = (locale === 'en')
      ? 'https://www.educatemagis.org/jesedu-jogja2020-virtual-colloquium/'
      : 'https://www.educatemagis.org/' + locale + '/jesedu-jogja2020-virtual-colloquium/';

    return (
      <div>
        <Crate className="container-decoration container-decoration-top hidden md:block">
          <Center>
            <div className="flex h-10 bg-pink-900 rounded-tl-full items-end text-osm font-semibold text-white justify-end" style={{ marginLeft: `130px`, paddingLeft: `50px` }}>
              {/* <span className={itemClass}>
                <a href={vc2024Link} target="_blank" rel="noopener noreferrer">
                  <sup><FiExternalLink className="inline-block" /></sup>&nbsp;
                  <FormattedMessage id="Virtual Colloquium" />
                </a>
              </span> */}
              <Location>
                {({ location }) => {
                  return <span className={this.navClass(location.pathname, "", ["/"])}>
                    <TranslateLink className={itemClass} to="/">
                      <FormattedMessage id="About The Seminar" />
                    </TranslateLink>
                  </span>
                }}
              </Location>
              <span className={this.navClass("", ["/page-programme/"])}>
                <TranslateLink className={itemClass} to="/page-programme/">
                  <FormattedMessage id="Programme" />
                </TranslateLink>
              </span>
              <Location>
                {({ location }) => {
                  return <span className={this.navClass(location.pathname, "", ["/page-speakers/"])}>
                    <TranslateLink className={itemClass} to="/page-speakers/">
                      <FormattedMessage id="Speakers" />
                    </TranslateLink>
                  </span>
                }}
              </Location>
              <Location>
                {({ location }) => {
                  return <span
                    className={
                      this.navClass(
                        location.pathname,
                        "px-1 py-1 block relative",
                        [
                          "/page-participants-accommodation/",
                          "/page-participants-pilgrimage/",
                          "/page-participants-tour/",
                          "/page-participants-registration/"
                        ]
                      )}>
                    <span className="flex items-center">
                      <span className="pr-1">
                        <FormattedMessage id="Participants" />
                      </span>
                      <FiChevronDown />
                    </span>
                    <SubMenu>
                      <TranslateLink className="block border-b border-white px-3 py-1" to="/page-participants-accommodation/">
                        <FormattedMessage id="Transportation Accommodation" />
                      </TranslateLink>
                      <TranslateLink className="block border-b border-white px-3 py-1" to="/page-participants-pilgrimage/">
                        <FormattedMessage id="Pilgrimage" />
                      </TranslateLink>
                      <TranslateLink className="block border-b border-white px-3 py-1" to="/page-participants-tour/">
                        <FormattedMessage id="Optional Tour" />
                      </TranslateLink>
                      {/* <TranslateLink className="block px-3 py-1" to="/page-participants-registration/">
                    <FormattedMessage id="Registration" />
                  </TranslateLink> */}
                    </SubMenu>
                  </span>
                }}
              </Location>
              <Location>
                {({ location }) => {
                  return <span className={this.navClass(location.pathname, "", ["/page-contact/"])}>
                    <TranslateLink className={itemClass} to="/page-contact/">
                      <FormattedMessage id="Contact Us" />
                    </TranslateLink>
                  </span>
                }}
              </Location>
            </div>
          </Center>
        </Crate>
        <Crate>
          <div className="flex justify-center bg-white px-5 md:px-0 text-red-700">
            <div>
              <p className="mb-1 text-center">
                <FormattedMessage id="Join the II Seminar JESEDU-Jogja2024 by following the live streamed sessions from Indonesia!" />
              </p>
              <p className="mt-1 text-center">
                <u>
                  <strong>
                    {(locale === 'en') &&
                      <a href="https://www.educatemagis.org/jesedu-jogja2024-live-stream/" target="_blank" rel="noreferrer">
                        <FormattedMessage id="Access the live streamed sessions here" />
                      </a>
                    }
                    {(locale === 'es') &&
                      <a href="https://www.educatemagis.org/es/jesedu-jogja2024-live-stream/" target="_blank" rel="noreferrer">
                        <FormattedMessage id="Access the live streamed sessions here" />
                      </a>
                    }
                    {(locale === 'fr') &&
                      <a href="https://www.educatemagis.org/fr/jesedu-jogja2024-live-stream/" target="_blank" rel="noreferrer">
                        <FormattedMessage id="Access the live streamed sessions here" />
                      </a>
                    }
                  </strong>
                </u>
              </p>
            </div>
          </div>
          {(messageState === 'show') &&
            <div className="pb-3">
              <div className="p-3 bg-blue-100 relative">
                <div onClick={this.toggleMessage} className="absolute top-0 right-0">
                  <FiX className='m-3' />
                </div>
                {(locale === 'en') &&
                  <div>
                    <h2>OFFICIAL ANNOUNCEMENT:</h2>
                    <h3>NEW DATES FOR THE COLLOQUIUM</h3>
                    <p></p>
                  </div>
                }
                {(locale === 'es') &&
                  <div>
                    <h2>ANUNCIO OFICIAL:</h2>
                    <h3>NUEVAS FECHAS PARA EL COLOQUIO</h3>
                    <p></p>
                  </div>
                }
                {(locale === 'fr') &&
                  <div>
                    <h2>ANNONCE OFFICIELLE</h2>
                    <h3>NOUVELLES DATES DU COLLOQUE</h3>
                    <p></p>
                  </div>
                }
              </div>
            </div>
          }

        </Crate >
      </div >
    )
  }
}

export default ComponentNavTop