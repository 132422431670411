import React from "react"
import styled from "@emotion/styled"

class HorizontalRule extends React.Component {
  
  render() {
    
    const Span = styled.span`
      width: 35px;
      height: 5px;
      display: inline-block;
    `

    return (
      <p className="w-full my-3" style={{fontSize: `0px`}}>
        <Span className="bg-pink-700"></Span>
        <Span className="bg-yellow-700"></Span>
        <Span className="bg-purple-700"></Span>
        <Span className="bg-blue-700"></Span>
      </p>
    )
  }
}

export default HorizontalRule