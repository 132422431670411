import React from 'react'

import Translate from '../components/translateProvider'
import Header from "../components/header"
import Nav from "../components/navTop"
import SubHeader from "../components/subHeader"

import Footer from "../components/footer"
import Crate from "../components/crate"
import Seo from "../components/seo"

class PageTemplate extends React.Component {

  render() {

    const props = this.props;
    const locale = props.locale;
    const title = props.title;
    const icon = props.icon;

    return (

      <Translate locale={locale}>
        <div>
          <Seo title={title} locale={locale}></Seo>
          <Header locale={locale} headerImage={props.headerImage}/>
          <Nav locale={locale}></Nav>
          <SubHeader title={title} icon={icon} />
          {this.props.children}
          <Crate className="container-decoration container-decoration-bottom hidden md:block">
            <div className="bg-pink-900 h-10" style={{ borderRadius: `50px 0 0 0` }}></div>
          </Crate>
          <Footer locale={locale}></Footer>
        </div>
      </Translate>
    )
  }
}

export default PageTemplate
